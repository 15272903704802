<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Tarification</h4>
    </div>
    <hr class="" />
    <div class="row d-flex justify-content-center">
      <div class="col-lg-5" >
        <div class="card z-depth-1" style="min-height: 400px">
          <div class="card-body">
            <div class="__header d-flex justify-content-between">
              <h3 class="font-weight-bold">Startup</h3>
              <div>
                <span style="font-weight: 600"
                  >{{ pricing.price_s | formatNumber }} FCFA</span
                ><br />
                <span style="font-size: 0.8rem">
                  Par Site
                </span>
                <span> & </span>
                <span style="font-size: 0.8rem">
                  Par An
                </span>
              </div>
            </div>
            <div class="__body mt-3" style="font-size: 0.9em">
              <h6>L' offre comprend:</h6>
              <ul class="listing0">
                <li>Génération des rapports</li>
                <li>Gestion des clients, articles, sites et depots</li>
                <li>Génération des factures normalisées</li>
              </ul>
            </div>
            <div class="__footer mt-5 d-flex justify-content-center">
              <button type="submit" class="btn btn-blue btn-md" @click="ShowMSub({price: pricing.price_s, name:'startup'})">
                Continuer avec Startup
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5" >
        <div class="card z-depth-1" style="min-height: 400px">
          <div class="card-body">
            <div class="__header d-flex justify-content-between">
              <h3 class="font-weight-bold">Business</h3>
              <div>
                <span style="font-weight: 600"
                  >{{ pricing.price_b | formatNumber }} FCFA</span
                ><br />
                <span style="font-size: 0.8rem">
                  Tous les sites
                </span>
                <span> & </span>
                <span style="font-size: 0.8rem">
                  Par An
                </span>
              </div>
            </div>
            <div class="__body mt-3" style="font-size: 0.9em">
              <h6>L' offre comprend:</h6>
              <ul class="listing0">
                <li>Génération des rapports</li>
                <li>Nombre de sites illimités</li>
                <li>Gestion des clients, articles, sites et depots</li>
                <li>Génération des factures normalisées</li>
              </ul>
            </div>
            <div class="__footer mt-5 d-flex justify-content-center">
              <button type="submit" class="btn btn-blue btn-md" @click="ShowMSub({price: pricing.price_b, name:'business'})">
                Continuer avec Business
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mdb-modal :show="modalSub.show" @close="modalSub.show = false" size="lg" info>
      <mdb-modal-header>
        <mdb-modal-title>Abonnements</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="px-1 py-1">
        <mdb-row>
          <mdb-col lg="5">
            <mdb-card-body class="presentation-card-sub" v-if="!$isMobile()">
              <h5 class="title" style="font-weight: 400; text-transform:uppercase">{{ form.name }}  - 12 mois</h5>
              <div class="amount font-weight-bold" style="font-size: 2em">
                {{ (amountPrice) | formatNumber }} XOF
              </div>
              <div class="details-price">
                <span style="font-size: 0.8rem" v-if="form.name === 'startup'">
                  Par Site
                </span>
                <span> & </span>
                <span style="font-size: 0.8rem">
                  Par An
                </span>
              </div>
            </mdb-card-body>
          </mdb-col>
          <mdb-col lg="7">
            <h5 style="font-weight: 400;text-transform:uppercase">{{ form.name }} - 12 mois</h5>
            <mdb-card-body class="px-0 mt-2">
              <form @submit.prevent="validatePayment()">
                <mdb-row>
                  <mdb-col sm="12" v-if="form.name.toLowerCase() === 'startup'">
                    <p>Choisissez les sites concernees par l'abonnement.</p>
                    <mdb-select
                      label="Sites"
                      outline
                      flipOnScroll
                      v-on:getValue="getSite"
                      multiple
                      selectAll
                      selectAllPlaceholder="Tout selectionner"
                      v-model="siteOption"
                    ></mdb-select>
                  </mdb-col>
                  <mdb-col sm="12">
                    <div>
                      <mdb-tbl responsive>
                        <mdb-tbl-head>
                          <tr>
                            <th class="text-left">Nombre de site</th>
                            <th class="text-right">Montant</th>
                          </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body v-if="form.name.toLowerCase() === 'startup'">
                          <tr class="">
                            <td class="text-left">
                              {{ getNbrSiteSelected | formatNumber }}
                            </td>
                            <td class="text-right font-weight-bold">
                              {{ getCalculTotal | formatNumber }} XOF
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-left"></td>
                            <td
                              class="text-right text-primary font-weight-bold"
                              style="font-size: 1.2em"
                            >
                              {{ getCalculTotal | formatNumber }} XOF
                            </td>
                          </tr>
                        </mdb-tbl-body>
                        <mdb-tbl-body v-if="form.name.toLowerCase() === 'business'">
                          <tr class="">
                            <td class="text-left">Sites illimités</td>
                            <td class="text-right font-weight-bold">
                              {{ getCalculTotal | formatNumber }} XOF
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-left"></td>
                            <td
                              class="text-right text-primary font-weight-bold"
                              style="font-size: 1.2em"
                            >
                              {{ getCalculTotal | formatNumber }} XOF
                            </td>
                          </tr>
                        </mdb-tbl-body>
                      </mdb-tbl>
                    </div>
                  </mdb-col>
                  <mdb-col
                    sm="12"
                    class="d-flex justify-content-between align-items-center pt-5"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-blue btn-md"
                      @click="modalSub.show = false"
                    >
                      Annuler
                    </button>
                    <button type="submit" class="btn btn-blue btn-md">
                      Payer maintenant
                    </button>
                  </mdb-col>
                </mdb-row>
              </form>
            </mdb-card-body>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>
<script>
import {
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbRow,
  mdbCol,
  mdbCardBody,
  mdbSelect,
} from "mdbvue";
export default {
  name: "sub-setting",

  components: {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbRow,
    mdbCol,
    mdbCardBody,
    mdbSelect,
  },
  data() {
    return {
      pricing: {},
      modalSub: {
        show: false,
      },
      createLance: false,
      amountPrice: 0,
      sites: [],
      siteOption: [],
      registrationStep: 2,
      registrationStepper: [
        { icon: "folder", far: true, name: "Basic Information" },
        { icon: "pencil-alt", name: "Personal Data" },
        { icon: "image", far: true, name: "Terms and Conditions" },
        { icon: "check", name: "Finish" },
      ],
      form: {
        type_payment: "mm",
        name: "",
        regime: "",
        p: "",
        sites: [],
        amount: 0,
        per_site: "",
        frequence: "",
        transactionId: "",
        account: "",
        company_id: this.$store.state.auth.company.id,
      },
    };
  },
  methods: {
    closeMSub() {
      this.modalSub.show = false;
      this.loadSites();
    },
    ShowMSub(price) {
      this.amountPrice = price.price;
      this.form.name = price.name;
      this.form.regime = this.$store.state.auth.company.setting.regime_fiscal;
      this.modalSub.show = true;
    },

    async loadSubPricing() {
      this.$nprogress.start();

      await this.$store
        .dispatch("subscription_pack/findPrice")
        .then((response) => {
          this.$nprogress.done();

          this.pricing = response.data.subpaks;
        })
        .catch((error) => {
          this.$nprogress.done();

          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadSites() {
      this.$nprogress.start();

      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.sites = response.data.sites.filter((site) => site.is_subscribed === 0);

          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },
    getSite(value) {
      this.form.sites = value;
    },

    async validatePayment() {
      switch (
        `${this.form.name.toLowerCase()}`
      ) {
        case "business":
          break;
        default:
          if (this.form.sites.length <= 0) {
            this.$notify({
              message: "Vous devez sélectionner un site au moins.",
              type: "warning",
            });
            return;
          }
          this.form.amount = this.getNbrSiteSelected * this.getPerAn;
          break;
      }

      window.openKkiapayWidget({
        amount: this.form.amount,
        position: "center",
        callback: "",
        data: "RTEC",
        sandbox: false,
        theme: "#1A2980",
        key: "df0f4343afe82dc924ebc5a734c9afb006201a41",
        // =======================================
        // Key Sandbox :fcacd9b00d9411ecb46e736c002ecf6b
        // key:"fcacd9b00d9411ecb46e736c002ecf6b"
      });


      window.addSuccessListener((response) => {
        this.form.transactionId = response.transactionId;
        this.form.account = response.account;

        if (!this.createLance) {
          this.createSub();
        }
      });
    },

    async createSub() {
      if (!this.createLance) {
        this.createLance = true;
        this.$nprogress.start();
        await this.$store
          .dispatch("subscription/save", this.form)
          .then(() => {
            this.createLance = false;
            this.$nprogress.done();
            this.form.sites = [];
            this.$router.push("/parametres/subscriptions");
            // this.$notify({
            //   message: res.data.message,
            //   type: "success",
            // });
          })
          .catch((error) => {
            this.createLance = false;
            this.$nprogress.done();
            this.$notify({
              message: error.response.data.message || error.response.data.error,
              type: "danger",
            });
          });
      }
    },

    calculTotal() {
      if (this.form.name.toLowerCase() === "business") {
        return (this.form.amount = this.amountPrice );
      } else {
        return (this.form.amount = this.getNbrSiteSelected * this.getPerAn);
      }
    },
  },
  created() {
    this.loadSites();
    this.loadSubPricing();
  },
  mounted(){
    this.$store.dispatch("lock/resetUltime",86400)
  },
  destroyed(){
    this.$store.dispatch("lock/resetUltime",10)
  },
  computed: {
    getCalculTotal() {
      return this.calculTotal();
    },
    getNbrSiteSelected() {
      return this.form.sites.length;
    },
    getPerAn() {
      return this.amountPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
.kkp {
  background-color: rgb(0, 110, 255) !important;

  &.hover {
    background-color: rgb(0, 110, 255) !important;

    border: 1px solid rgb(0, 110, 255);
  }
}

#theme-dark .modal-dialog.modal-notify .modal-body {
  padding: 1.5rem;
  color: white;
}
</style>
<style>
.list-group a:hover,
.list-group button:hover {
  transition: 0.1s;
}

ul.listing0 li {
  margin-top: 15px;
}
</style>
